<template>
  <div>
    <!-- Search and Action Buttons -->
    <div class="row justify-content-end align-items-end mb-3">
      
      <div class="col-auto">
        <button @click="refresh" class="btn btn-secondary">
          <i class="bi bi-arrow-repeat"></i>
          Actualiser
        </button>
      </div>
      <div class="col-auto">
        <button @click="$router.push({ name: 'customers-create' })" class="btn btn-primary">
          <i class="bi bi-plus-square"></i>
          Créer un nouveau client
        </button>
      </div>
    </div>

    <!-- DataTable -->
    <vue-data-table 
      :headers="headers" 
      :data="customers" 
      :buttons="tableButtons" 
      :summableColumns="['amount']" 
    />
  </div>
</template>


<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      headers: [
        { label: "Référence", field: "reference" },
        { label: "Nom Complet", field: "fullName" },
        { label: "Naissance", field: "birthDate" },
        { label: "Adresse", field: "address" },
        { label: "Ville", field: "city" },
        { label: "Téléphone", field: "phone" },
        { label: "Fixe", field: "homePhone" },
        { label: "E-mail", field: "email" },
        { label: "Profession", field: "profession" },
        { label: "Connexion", field: "last_seen_at" },
       
      ],
      tableButtons: [
        {
          label: "",
          class: "text-primary",
          icon: "bi bi-calendar2-plus-fill",
          handler: (row) => this.$router.push({ name: 'reservations-create', query: { fieldReference: row.reference } })
        },
        {
          label: "",
          class: "text-success",
          icon: "bi bi-eye-fill",
          handler: (row) => this.$router.push({ name: 'customers-details', params: { reference: row.reference } })
        },
        {
          label: "",
          class: "text-secondary",
          icon: "bi bi-pencil-square",
          handler: (row) => this.$router.push({ name: 'customers-edit', params: { reference: row.reference } })
        },
        {
          label: "",
          class: "text-danger",
          icon: "bi bi-trash",
          handler: (row) => this.remove(row)
        }
      ]
    };
  },
  computed: {
    ...mapGetters("customer", {
      customers: "getCustomers",
    }), 
  },
  mounted() {
    this.refresh();
  },
  methods: {
    
    async refresh() {
      await this.$store.dispatch("customer/getAll");
    },
    async remove(data) {
      let customer = data;
      await this.$confirm({
        message: "Vous voulez supprimer le client " + customer.fullName + " ?",
        button: { no: "Non", yes: "Oui" },
        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("customer/destroy", customer.reference);
          }
        },
      });
    }
  }
};
</script>
